.main img{
    width:75%;
   /* filter: grayscale();*/
    border-radius: 50%;
   box-shadow: -8px 13px 42px 8px rgba(0,0,0,0.56);
}

.main img:hover{
    filter: grayscale();
}

.main h3{
    padding-top: 2rem;
}

.main .underline{
    border: 4px solid transparent;
    display: inline-block;
    vertical-align: top;
    line-height: 36px;
    text-transform: uppercase;
    color: black;
    letter-spacing: 0.2em;
    text-align: center;
    font-size: 1.4rem;
   /* margin:10px;*/
    position: relative;
}

.underline::after {
  width: 0;
  height: 2px;
  display: block;
  background: blue;
  transition: .4s;
  content: '';
  position: absolute;
  left: 50%;
  bottom: -5%;
  transform: translate(-50%, -50%);
}

.main:hover .underline::after{
    width:100%;
}