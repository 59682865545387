.menuBar li a:hover {
	 /* background: none!important; */
}

.menuBar li a:focus {
	 box-shadow: none!important;
}

.menuBar .p-submenu-icon{
	display: none!important;
}

.menuBar ul{
	 width: unset!important
}

.menuBar li a {
	 white-space: nowrap;
}






nav {
  background-color: #797e87;
  display: flex;
  /*justify-content: space-between;*/
 /* align-items: center;*/
  color: white;
  padding: 0 1rem!important;
  z-index: 1100!important;
  position: fixed!important; /* Set the navbar to fixed position */
  width:100%;
/* top: 0!important; /* Position the navbar at the top of the page */ 
 /* overflow: hidden!important;*/
 box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),
             0px 4px 5px 0px rgba(0,0,0,0.14),
             0px 1px 10px 0px rgba(0,0,0,0.12);
}

nav h1 {
  text-align: center;
}

nav li {
  list-style: none;
	padding-right:15px;
}

nav a {
  text-decoration: none;
  color: inherit;
  cursor: poin   ter;
  opacity: 0.9;
}

nav li a {
  color: white!important;
}

nav a:hover {
  opacity: 1;
}

nav {
  display: flex;
}

#navbarNav{
  direction: rtl;
}

nav li:first-child {
  margin-left: 0;
}

nav li:last-child {
  margin-right: 0;
}

@media (max-width: 1000px) {
  header{
    padding: 0px 50px;
  }
  nav li a {
  float:right!important;
}
}


@media (max-width: 700px) {
  header{
    flex-direction: column;
  }

}

li.dropdown_settings {
    display: inline-block;
}

.dropdown-content {
    display: none;
	/* margin-top: 5px; */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1; 
	position: absolute;
	background-color: #6c757d!important;
}

.dropdown-content a {
    color: black;
    padding: 5px 11px 5px;
    text-decoration: none;
    display: block;
    text-align: right;
} 
.dropdown-content a:hover {background-color: #efdcdc}

.dropdown_settings:hover .dropdown-content {display: block;}
