/**Lists****/

.pCard div.p-card-body {
	padding: 0rem;
}

.p-card div.p-card-content {
	padding: 8px 0;
}

.p-card div.p-divider-horizontal {
	margin: 1px 0;
}
.divUl {
	padding: 0;
	margin-bottom: 0em;
}

/**List****/

/**item****/
.item {
	top: 50%;
	right: 16px;
	position: absolute;
	transform: translateY(-50%);
}
.liDiv {
	padding-left: 16px;
	padding-right: 16px;

	display: flex;
	position: relative;
	padding-top: 8px;
	padding-bottom: 8px;
}
.divText {
	text-align: right;
	flex: 1 1 auto;
	margin-top: 4px;
	margin-bottom: 4px;
}

/******EditItem****/

.divInputTextEdit{
	width: 100%;
	border-right: none;
	border-left: none;
	border-top: none;
	border-radius: 0;
	border-bottom: 2px solid #3f51b5;
	font-size:16px;
}

.divInputTextEdit:focus {
	border-left: none;
	outline: 0;
}

/**************Form/****/

input.divInputTextAdd {
	width: 100%;
	border-right: none;
	border-left: none;
	border-top: none;
	border-radius: 0;
	border-bottom: 2px solid #3f51b5;
}
input.divInputTextAdd:enabled:focus,
input.divInputTextAdd:enabled:hover{
	box-shadow: none;
	outline: 0;
	border-bottom: 2px solid #3f51b5;
}

.cardForm div.p-card-body {
	padding: 1.5rem 0 0 0;
}

.cardForm input.p-inputtext {
	padding: 0.5rem 0.1rem 0rem 0.5rem;
}

.addItemLabel {
	right: 0;
}

.p-float-label label.addItemLabel {
	left: unset;
	margin-top: -0.5rem;
}