.bg{
	background-color: var(--surface-200);
	position: absolute; 
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

table th {
	font-size: 15px;
	font-weight: normal !important;
	padding: 10px 10px !important;
}

table td {
	font-size: 12px;
	padding: 12px 6px !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	direction: rtl;
}

.p-datatable .p-datatable-tbody > tr > td,
.p-datatable-thead > tr > th {
	text-align: center !important;
}

body .p-dropdown:not(.p-disabled).p-focus {
	box-shadow: 0 0 0 0;
}

.container {
	padding-right: 15px;
	padding-left: 15px;
	margin: 0 auto;
	margin-top: 50px;
}

.p-progress-spinner {
	display: block;
	position: absolute !important;
	left: 50%;
	top: 50%;
	z-index: 1;
	width: 150px !important;
	height: 150px !important;
	margin: -130px 0 0 -75px !important;
}

.p-datatable .p-column-header-content {
    display: block!important;
}

@media (min-width: 1770px) {
	.container {
		max-width: 1560px;
	}
}
@media (max-width: 1769px) and (min-width: 1530px) {
	.container {
		max-width: 1350px;
	}
}
@media (max-width: 1529px) and (min-width: 1320px) {
	.container {
		max-width: 1150px;
	}
}
@media (max-width: 1319px) and (min-width: 1180px) {
	.container {
		max-width: 990px;
	}
}
@media (max-width: 1179px) and (min-width: 1060px) {
	.container {
		max-width: 890px;
	}
}
@media (max-width: 1059px) and (min-width: 992px) {
	.container {
		max-width: 790px;
	}
}
/******************************************/
/*Sidebar*/
.icon-bar {
	position: fixed;
	top: 40%;
	transform: translateY(-50%);
	width: 70px;
	padding-left: 10px;
	background-color: white;
	margin-left: 15px;
	border: 1px solid #ccc;
	border-radius: 25px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
}

.icon-bar li {
	list-style: none;
}
.icon-bar a {
	transition: all 0.3s ease;
	display: block;
	text-align: center;
	width: 40px;
}

.icon-bar a img:hover {
	margin-left: 15px;
	transition: all 0.3s ease;
}

.icon-bar a img {
	width: 45px;
	padding-bottom: 5px;
	padding-top: 5px;
}

.foot_wrapper {
	min-height: 50px;
	margin: 0 auto;
	padding-right: 200px;
	padding-left: 200px;
}

@media (max-width: 992px) and (min-width: 568px) {
	.icon-bar {
		width: auto;
		display: flex;
		justify-content: center;
		position: relative;
		top: 70px;
		direction: rtl;
		border-radius: unset;
		border:none;
	}

	.icon-bar li {
		margin-right: 15px;
	}

	.icon-bar a img:hover {
		margin: 0;
		transition: none;
	}
	.foot_wrapper {
		padding-right: 10px;
		padding-left: 10px;
	}
}

@media (max-width: 576px) {
	.container {
		max-width: 460px;
	}

	.icon-bar {
		width: auto;
		display: flex;
		justify-content: center;
		position: relative;
		top: 70px;
		direction: rtl;
		border-radius: unset;
		border:none;
	}

	.icon-bar li {
		margin-right: 15px;
	}

	.icon-bar a img:hover {
		margin-left: 0;
		transition: none;
	}
	.foot_wrapper {
		padding-right: 10px;
		padding-left: 10px;
	}
}

/*******************************/
p-datepicker-group {
	direction: rtl;
}

.p-datepicker {
	width: 320px !important;
	padding: 0.5em !important;
}

.p-datepicker table td > span {
	padding: 0 !important;
}

.p-datepicker table td {
	font-size: 12px;
	padding: 3px 2px !important;
}
.p-datepicker table {
	margin: 0 !important;
}
.p-datepicker-calendar thead tr th > span {
	color: black !important;
	font-weight: bold;
	font-size: 13px;
}
/*******************************/

.p-column-filter-menu-button:focus {
    box-shadow: none!important;
}

.p-multiselect:not(.p-disabled).p-focus{
	 box-shadow: none!important;
}