.buttonCustom span{
	font-size: 1.2rem!important; 
	outline: none;

}
.buttonCustom span:hover{
	color: purple;
	 transition: background-position 0.5s ease-out;
    border-color: var(--purple-700);
}

.p-button:focus {
    box-shadow: none!important;
}

.p-overlaypanel .p-overlaypanel-content {
    padding: 0.75rem!important;
}

.p-checkbox-box.p-focus{
	box-shadow: none!important;
}

.p-datatable .p-datatable-header {
	padding: 0.4rem 1rem!important;
}

.popupMenuColumns{
	max-height: 400px;
	overflow: auto;
}

.popupMenuColumns ul li a{
	justify-content: flex-end;
}





.txtFont > span{font-family: 'Varela Round', sans-serif!important;}
.myFont{ font-family: 'Varela Round', sans-serif!important; }

.menuColFilterTitle:focus{ outline-width: 0;}
.menuColFilter:focus{ outline-width: 0;}

.menuColFilterReset{
    padding-left: 20px!important;
    padding-top: 8px!important;
    color: blue;
}

.menuTop{ top:45px!important;}

.p-dropdown-item { 
    font-family: 'Varela Round', sans-serif!important;
    text-align: right!important;
}
.p-multiselect-item{ font-family: 'Varela Round', sans-serif!important; }
.p-multiselect-item label{ vertical-align: top!important; }

.inptDiv{
    float: right;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.txtinput label{
    right: 0!important;
    left:unset;
    direction: rtl;
    transform-origin: top right;
    font-family: 'Varela Round', sans-serif!important;
}

input {
     font-family: 'Varela Round', sans-serif!important;
     direction: rtl;
}

.txtinput{
     float: right;
     width: 130px!important;
}

.dialogClass{
 /*   flex: 0 0 auto!important;
    margin: 0!important;*/
    padding: 12px 19px 12px!important;
  /*  text-align: center;*/
   
}

.dialogClass h2{
     font-weight: 700!important;
     font-family: 'Varela Round', sans-serif!important;
     font-size:22px;
}

.closeButton {
    position: absolute;
    right: 0;
    top:0;
    color: grey;
  }
 
