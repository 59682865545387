.dropDown.p-dropdown .p-dropdown-label{
	padding: 0.25rem;
	padding-right: 0.5rem;
	font-size: 12px;
	font-family: 'Varela Round', sans-serif!important;
	align-self: center;
}

.p-dropdown .p-dropdown-trigger {
    width: 2rem!important;
}

.Modal{
    top:70px!important;
    max-width: 940px!important;
    width:100%!important;
	
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item{
	padding:8px!important;
}

.itemDropDown{
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.p-inputtextarea:enabled:focus{
	box-shadow: none!important;
}

.inputArea{
	border-color: #b29797!important;
}

.inputArea:enabled:hover{
	border-color: #b29797!important;
}

.buttonTextFont .p-button-label {
     font-weight: 400; 
}

.Modal .p-dialog-footer{
	padding: 0!important;
	border-top: 1px solid #eee!important;
}

.tabPane .p-tabview-nav-link{
	padding: 10px 20px!important;
}
.tabPane .p-tabview-nav-link span{
	margin-left: 6px;
	font-weight: 500;
}
.tabPane .p-tabview-nav-link:focus{
	box-shadow:none!important;
}

.p-tabview-nav{
	border-bottom-width: 1px!important;
	direction: rtl;
}

.disabledInput{
	background-color:#F5F6EC!important;
}
.refsTablePaginator{
	direction: ltr;
}

.refsTablePaginator.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2rem!important;
    height: 2rem!important;
 
}
.refsTablePaginator button{
	 height: 2rem!important;
}

.pannelDropDown .p-overlaypanel-content{
	padding:0!important;
}
.directionRTL{
	direction: rtl;
}
 .plcholdr::placeholder {
    opacity: .5;
}

.dropDown .p-dropdown-trigger-icon{
	color: var(--surface-400) !important;
	border-right-width: 1px !important;
    border-right-style: solid;
	border-color: var(--surface-300) !important;
	margin-left: 3px;
	padding-right: 0.25rem !important;
}
.dropDown .p-dropdown-trigger-icon:hover{
	color: var(--surface-800) !important;
}

.cnfDialaog{
	direction: rtl;
	bottom: 120px;
	width: 350px;
}
.cnfDialaog .p-dialog-footer{
	padding: 0!important;
	direction: ltr;
	
}










.ModalHQ{
    top:70px!important;
    max-width: 700px!important;
    width:auto!important;
}
.ModalCust{
    top:70px!important;
    max-width: 1000px!important;
    width:auto!important;
}

.inptBorder{border: 1px solid #b29797!important;}
.redBorder{ border: 1px solid red!important;}

.inpHght, .ant-select-selection{
        height:30px!important;
}

label{
    display: inline;
    margin-bottom: 0px!important; 
}

th.refTable, td.refTable {
  text-align: right !important;
  padding: 6px 10px!important;
  border-right: 1px solid #e8e8e8!important;
  
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 130px;
  
}
th.refTable{
    font-size: 14px;
    background:#005E8E!important;
	color:white!important;
}



@media (min-width : 768px) { .fr{	float: right;}}  
